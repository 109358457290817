import React from "react"
import Button from "./button"
import Logo from "../images/main-logo.svg";
import PersonSitting from "../images/person-sitting-gold.webp"

const Header = () => {
  return (
    <div className="header-body container">
      <div className="d-flex align-items-center flex-column flex-md-row">
        <div className="">
          <img style={{ width:"425px", maxWidth:"90%" }} src={Logo} alt="main logo"/>
          <p className="mt-3 w-100">Unlock the potential of your brand and step confidently into the future. Are you ready to take your brand to the next level?
          <br/><br/>
          <span className="font-weight-bold"> Let's get started.</span>
          </p>
          <Button
            className="mt-1"
            secondary
            onClick={() => window.location = "https://alvarezbranding.hbportal.co/public/64f929dfc761c400e5621743"}>
            See our work
          </Button>
        </div>
        <div className="flex-xl-shrink-0 text-right" >
          <img style={{width: "100%", maxWidth:"600px"}}  src={PersonSitting} alt="person sitting"/>
        </div>
      </div>
    </div>
  )
}

export default Header;
