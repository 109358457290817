import React, { Component } from "react"

class Modal extends Component {
  render() {
    const {
      handleClose,
      show,
      children,
    } = this.props
    const showHideClassName = show
      ? "modal display-block"
      : "modal display-none"
    return (
      <div className={showHideClassName}>
        <div className="modal-scroll-wrap">
          <section className="modal-main">
            <div className="a-position-relative">
              <div
                className="modal-closeout"
                onClick={handleClose}
              >
                x
              </div>
              {children}
            </div>
          </section>
        </div>
      </div>
    )
  }
}
export default Modal
