import React  from "react";
import Layout from "./components/layout";
import Testimonial from "./components/Testimonial";
import Header from "./components/header";
import CustomerLogos from "./components/customerlogos";
import FeaturedWork from "./components/FeaturedWork";

const IndexPage = () => {
    return (
	<Layout>
	  <Header/>
	  <FeaturedWork/>
	  <CustomerLogos className="mb-5 pb-5 mt-5 pt-5"/>
	  <Testimonial
	    clientimage="cristina-client.jpg"
	    name="Cristina Hoyt"
	    companyname="CristinaHoyt.com"
	      //clientUrl="http://www.nourishmbml.com"
	  >
            "Since I had my website updated my sales have gone up 300% with an
            easy to navigate website and integrated SEO. I’ve started to be able
            to attract clients I didn’t know were available."
	  </Testimonial>
	</Layout>
    );
}

export default IndexPage
