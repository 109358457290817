import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ARV from "../images/arv.png";
import CUAP from "../images/cuap.png.jpg";
import { useSwipeable } from 'react-swipeable';
//import './FeaturedWork.css';

const projects = [
    {
        id: 1,
        title: "Auburn Road",
        subTitle: ["Design", "Dev", "Branding"],
        image: ARV
    },
    {
        id: 2,
        title: "Cooking Up A Cure",
        subTitle: ["Graphic Design", "Branding"],
        image: CUAP,
    }
    // {
    //     id: 3,
    //     title: "Social Media App",
    //     subTitle: [],
    //     image: "/images/social.jpg",
    // },
];

const FeaturedWork = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === projects.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? projects.length - 1 : prevIndex - 1
        );
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => nextSlide(),
        onSwipedRight: () => prevSlide(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div className="">
          <h2 className="title a-margin-top--100 a-margin-bottom--50">Featured Work</h2>
          <div className="featured-work">
            <button className="nav-button prev" onClick={prevSlide}>←</button>
            <div className="carousel-container" {...handlers}>
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentIndex}
                  className="carousel-card"
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src={projects[currentIndex].image}
                    alt={projects[currentIndex].title}
                    className="card-image"
                  />
                  <div className="card-overlay">
                    <h2>{projects[currentIndex].title}</h2>
                    {projects[currentIndex].subTitle
                     .map(x => {
                         return <span>{x}</span>}
                         )}
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
            <button className="nav-button next" onClick={nextSlide}>→</button>
          </div>
        </div>
    );
};

export default FeaturedWork;
