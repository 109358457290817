import React, { Component, Fragment } from "react"
import "./layout.css"
import "../css/main.css"
import NavBar from "./NavBar";
import ReactGA from 'react-ga';

const trackingId = "UA-169863041-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

class Layout extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    const {
      children
    } = this.props;
  
  return (
    <Fragment>
      <NavBar/>
      <main>{children}</main>
      <footer>
        <div className="footer-logo">
        </div>
        <div className="container-inner"></div>
        <div className="copyright-wrap">
          © {new Date().getFullYear()},{` `}Alvarez Branding Co.
        </div>
      </footer>
    </Fragment>
  )
}

}
export default Layout
