import React from "react";
import ARV from "../images/auburn-road.svg";
import HT from "../images/happy-tails.svg";
import Haddon from "../images/haddon.svg";
import PG from "../images/papergreats.svg";
import NTS from "../images/night-to-shine.svg";
import M2 from "../images/m2.svg";
import WHE from "../images/whole-hearted-eating.svg";
import PB from "../images/pablo-batista.svg";

const CustomerLogos = ({ className }) => {
    const logos = [ARV, HT, Haddon, PG, NTS, M2, WHE, PB];
    const list = [...logos, ...logos];

    return (
	<div className={className}>
	    <h2 className="title">Brands that trust us</h2>
	    <div className="customer-logo-wrap col" listlength={list.length}>
		<div className="row mover-1 flex-nowrap">
		    {list.map((logo, index) => (
			<img 
			    key={`logo-${index}`}
			    src={logo} 
			    alt={`Customer Logo ${index + 1}`}
			    className="customer-logo col-2 container"
			/>
		    ))}
		</div>
	    </div>
	</div>
    );
};

export default CustomerLogos;
