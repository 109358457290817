import React, {useState} from "react";
import classnames from "classnames";
import AltLogo from "../images/alt-logo.webp";
import GreenBlob from "../images/greenblob.png";
import HamburgerMenu from "../images/hamburger-menu.svg";
import HamburgerMenuClose from "../images/hamburger-menu-close.svg";

const NavOverlay = ({menuOpen}) => {
  debugger
  return (
    <div className={classnames("nav-overlay", {"show": menuOpen})}>
      <ul>
        <li className="nav-overlay-item">Home</li>
        <li className="nav-overlay-item">Our Work</li>
        <li className="nav-overlay-item">Photography</li>
        <li className="nav-overlay-item">About</li>
        <li className="nav-overlay-item">Contact</li>
        {/* <li className="nav-overlay-sm d-flex"> */}
        {/*   <div></div> */}
        {/*   <div>a</div> */}
        {/*   <div>a</div> */}
        {/* </li> */}
      </ul>
      <img className="green-blob" src={GreenBlob} alt="green blob"/>
    </div>
  );
};
    
const NavBar = () => {
  const [menuOpen, toggleMenuOverlay] = useState(false);
  return (
    <div className="nav-bar">
      <div className="container d-flex justify-content-between">
        <img className="nav-logo" src={AltLogo} alt="alternate logo"/>
        <div className="d-flex justify-content-end">
          <img onClick={() => toggleMenuOverlay(!menuOpen)} className="hamburger-menu" src={menuOpen ? HamburgerMenuClose : HamburgerMenu} alt="menu icon"/>
        </div>
      </div>
      <NavOverlay menuOpen={menuOpen}/>
    </div>
  )
}

export default NavBar
